import { Link, graphql } from "gatsby";

import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import CarouselCategory from "../components/carousels/CarouselCategory";
import React from "react";

const Catalog = ({ data, pageContext }) => {
  const { categories } = data?.hasura;
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <React.Fragment>
      <div className="page-title-overlap bg-accent pt-2">
        <div className="container d-lg-flex justify-content-between py-4 py-lg-3">
          <div className="pl-lg-2 order-lg-2 mb-0 mb-lg-0 pt-lg-2 breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center">
            <Breadcrumb crumbs={crumbs} crumbSeparator="" />
          </div>
        </div>
      </div>
      {categories.map((e, i) => {
        return (
          <section className="container p-0 p-sm-4" key={`section${i}`}>
            <div className="bg-white rounded-lg overflow-hidden">
              <div className="m-3 pb-2 border-bottom">
                <Link className="" to={`/produkte/${e.slug}`}>
                  <span className="h4">{e.name}</span>
                </Link>
              </div>
              <CarouselCategory category={e} />
            </div>
          </section>
        );
      })}
    </React.Fragment>
  );
};

export default Catalog;

export const query = graphql`
  query produkte {
    hasura {
      categories(order_by: { sort: asc }, where: { active: { _eq: true } }) {
        id
        slug
        name
      }
    }
  }
`;

export const Head = () => {
  const OGIMAGE = `https://www.punsch-mobil.at/img/2cvVignette2.jpg`;
  const TITLE = `Dein Punsch-Lieferservice produkte`;
  const DESCRIPTION = `Punsch Lieferservice. Zustellung von heißem Punsch ! Lieferservice von heißem Punsch in Wien u. Umgebung von 11 bis 23 Uhr`;
  const PAGEURL = `https://www.punsch-mobil.at/produkte`;
  return (
    <>
      <title>{TITLE}</title>
      <link rel="canonical" href={PAGEURL} />
      <link
        rel="icon"
        type="image/png"
        href="https://www.punsch-mobil.at/favicon.png"
      />
      <meta name="description" content={DESCRIPTION} />
      <meta
        name="keywords"
        content={[
          "punsch lieferung",
          "crepes lieferservice",
          "lieferung",
          "wien",
          "punsch mobil",
          "punsch lieferservice",
          "punsch zustellung",
        ]}
      />
      <meta property="og:url" content={PAGEURL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={TITLE} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:image" content={OGIMAGE} />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:site"
        content={"lacreperiemobile" && `@lacreperiemobile`}
      />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:image" content={OGIMAGE} />

      <script type="application/ld+json">
        {`
  {
    "@context": "https://schema.org",
    "@type": "Website",
    "url": "${PAGEURL}",
    "description": "${DESCRIPTION}",
    "image": "${OGIMAGE}"
  }
`}
      </script>
    </>
  );
};
